import { useDispatch, useSelector } from 'react-redux';
import fm from 'format-message';
import textStyles from 'config/branding/textStyles';
import useAccountQuery from 'queries/useAccountQuery';
import useTeamStoreUrlQuery from 'queries/useTeamStoreUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { signOut } from '@activebrands/core-web/state/auth/actions';
import stringReplace from '@grebban/utils/string/replaceSubStrings';
import StateButton from 'components/buttons/StateButton';
import ThemeButton from 'components/buttons/ThemeButton';
import LogOutIcon from 'components/icons/LogOutIcon';
import LogotypeFlat from 'components/icons/LogotypeFlat';
import OverlayHeader from 'components/overlays/OverlayHeader';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    width: '100vw',
    padding: '0px 4px',
    height: `calc(var(--vh) - var(--height-header) - 4px)`,
});

const Icon = styled('img', {
    marginRight: '8px',
    height: '16px',
    width: '16px',
});

const InnerWrapper = styled('div', {
    backgroundColor: 'var(--color-bg-navigation-overlay)',
    zIndex: 'var(--zindex-header)',
    height: '100%',
});

const NavigationWrapper = styled('div', {
    width: '100%',
    zIndex: 'calc(var(--zindex-header)',
    height: '90%',
    marginTop: '20px',
    paddingBottom: '10px',
});

const Navigation = styled('div', {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const Heading = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px',
    padding: '12px',
    borderBottom: '1px solid var(--color-border-account)',
});

const InternalMenu = styled('div', {
    width: '100%',
});

const NavigationOverlay = () => {
    const [css] = useStyletron();
    const { internalMenu, welcomeLabel, externalMenu } = useAccountQuery();
    const { teamStoreCategoryPage: teamStoreCategoryPagePath } = useTeamStoreUrlQuery();

    const activeClass = css({
        width: '100%',
        backgroundColor: 'var(--color-bg-navigation-active)',
        color: 'var(--color-contrast-dark)',
    });
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    const hasValidatedTeamAccount = user.teams?.length > 0;

    const firstName = { '{firstName}': user?.firstname || user?.email };
    const replacedName = stringReplace(welcomeLabel, firstName);

    return (
        <Wrapper>
            <InnerWrapper>
                <Heading>
                    <Paragraph $style={{ color: 'var(--color-text-account)' }} as="p" fontKeys="Primary/16_100_-05">
                        {fm('Menu')}
                    </Paragraph>
                    <LogotypeFlat width="127px" />
                    <OverlayHeader overlayId="account-navigation" />
                </Heading>
                <NavigationWrapper>
                    <Navigation>
                        <ul>
                            <div
                                className={css({
                                    marginBottom: '12px',
                                    padding: '0 12px',
                                    ...textStyles['Primary/12_100_-1'],
                                })}
                            >
                                <Paragraph $style={{ marginBottom: '20px', fontSize: '12px' }} as="span">
                                    {replacedName}
                                </Paragraph>
                            </div>
                            <InternalMenu>
                                {internalMenu &&
                                    internalMenu.map((internalMenuItem, index) => (
                                        <ThemeButton
                                            as={Link}
                                            activeClassName={activeClass}
                                            $style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                padding: '12px',
                                            }}
                                            fontKeys="Primary/18_100_-05"
                                            key={index}
                                            theme="link"
                                            rel="nofollow"
                                            to={internalMenuItem.link.url}
                                        >
                                            {internalMenuItem.label}
                                        </ThemeButton>
                                    ))}
                            </InternalMenu>
                            <div
                                className={css({
                                    margin: '12px',
                                })}
                            >
                                {hasValidatedTeamAccount ? (
                                    <StateButton
                                        iconSize="16px"
                                        fontKeys="Misc/16_100_-2"
                                        $style={{
                                            height: '40px',
                                            justifyContent: 'center',
                                        }}
                                        as={Link}
                                        rel="nofollow"
                                        theme="inverted"
                                        to={teamStoreCategoryPagePath}
                                    >
                                        {fm('Go to Team Store')}
                                    </StateButton>
                                ) : (
                                    <ThemeButton
                                        $style={{
                                            width: '100%',
                                            justifyContent: 'center',
                                        }}
                                        size="md"
                                        theme={{ type: 'solid' }}
                                        onClick={() => {
                                            overlay.open('validate-team-account');
                                        }}
                                    >
                                        {fm('Validate Team Account')}
                                    </ThemeButton>
                                )}
                            </div>
                        </ul>
                        <ul style={{ marginBottom: '10px' }}>
                            {externalMenu &&
                                externalMenu.map((externalMenuItem, index) => {
                                    const icon = externalMenuItem.icon[0]?.icon[0]?.filename;

                                    return (
                                        <li
                                            className={css({
                                                width: '100%',
                                                marginBottom: '12px',
                                            })}
                                            key={index}
                                        >
                                            <Link
                                                activeClassName={activeClass}
                                                className={css({
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '0 12px',
                                                })}
                                                rel="nofollow"
                                                to={externalMenuItem.link.url}
                                            >
                                                {icon && <Icon src={icon} />}
                                                <Paragraph as="p" fontKeys="Regular-20/100">
                                                    {externalMenuItem.label}
                                                </Paragraph>
                                            </Link>
                                        </li>
                                    );
                                })}
                            <li
                                className={css({
                                    width: '100%',
                                    borderTop: '1px solid var(--color-border-account)',
                                    padding: '18px 12px 0px 12px',
                                })}
                            >
                                <Link
                                    activeClassName={activeClass}
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        cursor: 'pointer',
                                    })}
                                    rel="nofollow"
                                    to="/"
                                    onClick={() => dispatch(signOut())}
                                >
                                    <LogOutIcon $style={{ marginRight: '8px' }} />
                                    <Paragraph as="p" fontKeys="Primary/14_100_-3">
                                        {fm('Log out')}
                                    </Paragraph>
                                </Link>
                            </li>
                        </ul>
                    </Navigation>
                </NavigationWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};

export default NavigationOverlay;
